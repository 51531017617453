import { NextImage } from "../../elements";
import { renderLineBreaks } from "../../../utils";
import { Section } from "..";
import { EditorJSContent } from "../../editor-js-content";
import { Title, Description, ImageWrapper, RichTextWrapper } from "./styles";

export const RichText = ({
  sectionTitle,
  sectionSubtitle,
  hideLineSeparation,
  title,
  content,
  image,
  imagePlacement,
  displaySetting

}) => {
  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      <RichTextWrapper hasImage={!!image} imagePlacement={imagePlacement}>
        {image && (
          <ImageWrapper>
            <NextImage media={image} width={360} />
          </ImageWrapper>
        )}
        <div>
          {title && <Title>{renderLineBreaks(title)}</Title>}
          {content && (
            <Description>
              <EditorJSContent content={content} />
            </Description>
          )}
        </div>
      </RichTextWrapper>
    </Section>
  );
};

export default RichText;
